<template>
  <div>
    <a-modal
      title="Verify OTP"
      v-model:visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="$emit('close', true)"
      okText="Verify"
      cancelText="Close"
    >
      <div type="flex" justify="center">
        <!-- <otp :digit-count="6" @update:otp="handleOnComplete($event)"></otp> -->
        <div class="container" id="otpContainer">
          <v-otp-input
            ref="otpValue"
            input-classes="otp-input"
            :num-inputs="6"
            :should-auto-focus="true"
            :is-input-num="true"
            separator="-"
            :conditionalClass="['one', 'two', 'three', 'four']"
            @on-complete="handleOnComplete"
            @on-change="handleOnChange"
          />
        </div>
      </div>

      <div class="OTPResend" v-if="disableResendButton">
        <p v-if="minutes > 0">
          Resend OTP in {{ minutes }} minutes {{ seconds }} seconds
        </p>
        <p v-else>Resend OTP in {{ seconds }} seconds</p>
      </div>
      <div v-if="!disableResendButton" class="buttons-s mt-4">
        <a-button
          type="primary"
          @click="handleResendOtp"
          :disabled="this.disableResendButton"
          size="large"
          html-type="submit"
          class="btn-block"
        >
          <span>Resend OTP</span>
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { ref, defineComponent, defineEmits } from 'vue';
import { mapActions } from 'vuex';
import otp from './OTP.vue';
import VOtpInput from 'vue3-otp-input';
export default defineComponent({
  components: {
    VOtpInput,
  },
  data() {
    return {
      otpValue: '',
      disableResendButton: true,
      timeToResendOtp: 300,
      timerCount: 300,
      minutes: null,
      seconds: null,
    };
  },
  emits: ['close'],
  setup() {
    const modalText = ref('Content of the modal');
    const visible = ref(true);
    const confirmLoading = ref(false);
    const showModal = () => {
      visible.value = true;
    };

    const handleCancel = () => {
      // console.log('at handle');
    };
    return {
      modalText,
      visible,
      confirmLoading,
      handleCancel,
      showModal,
    };
  },
  components: {
    otp,
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
            this.minutes = Math.floor(this.timerCount / 60);
            this.seconds = this.timerCount % 60;
          }, 1000);
        } else {
          this.disableResendButton = false;
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },

  methods: {
    ...mapActions(['resendOTP', 'verifyOTP']),
    handleOnComplete(enteredOtp) {
      this.otpValue = enteredOtp;
    },
    handleResendOtp() {
      this.disableResendButton = true;
      this.timerCount = this.timeToResendOtp;
      this.$refs.otpValue.clearInput();
      this.resendOTP();
    },
    handleOnChange(enteredOtp) {
      this.otpValue = enteredOtp;
    },
    async handleOk() {
      this.confirmLoading = true;
      await this.verifyOTP({
        OTP: this.otpValue,
      });
      this.confirmLoading = false;
    },
  },
});
</script>
<style>
.OTPResend {
  text-align: center;
}
#otpContainer {
  padding: 10px 40px;
}
.otp-input {
  width: 40px;
  height: 40px;
  padding: 10px 10px;
  margin: 0px 10px 10px 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}
/* Background colour of an input field with value */
.otp-input.is-complete {
  background-color: #e4e4e4;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#otpContainer input::placeholder {
  font-size: 15px;
  text-align: center;
  font-weight: 600;
}
</style>
